export default defineNuxtRouteMiddleware(async (to) => {
  if (useInIframe()) return undefined;

  const {
    finishOnboarding,
    viewIsFromSomeCategoryOfOnboarding,
    viewIsFromCategoryPostInstallationSetup,
    hasOnboarding,
    onboardingDone,
    nextCategory,
    loadWorkspace,
    buildViewRoute,
  } = useAppOnboardingMiddleware();

  await loadWorkspace();

  if (!hasOnboarding.value) return undefined;
  if (!viewIsFromSomeCategoryOfOnboarding(to.params.viewId)) return undefined;
  if (onboardingDone.value) return finishOnboarding();
  if (!nextCategory.value) return undefined;
  const nextRoute = buildViewRoute(nextCategory.value, to);
  if (to.fullPath === nextRoute.fullPath) return undefined;

  if (viewIsFromCategoryPostInstallationSetup(nextCategory.value, to.params.viewId as string)) return undefined;

  return navigateTo(nextRoute);
});
